import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import BtnLink from "../components/addon/Link"
import useScript from "../service/useScript"
import useDeviceDetect from "../service/useDeviceDetect"


const PatientCare = ({ data }) => {
    useScript("/assets/js/patient-care.js")
    const { isMobile } = useDeviceDetect()
    const defaultImageSrc = isMobile === true ? "https://via.placeholder.com/360x324" : "https://via.placeholder.com/1920x625"
    const defaultPatientServiceSrc = "https://via.placeholder.com/85x85"
    let metaTags = []
    let pageInfo = {}
    let components = []

    pageInfo = typeof (data?.allNodeTemplatePages?.edges[0]?.node) === "object" && Object.keys(data?.allNodeTemplatePages?.edges[0]?.node).length > 0 ? data?.allNodeTemplatePages?.edges[0]?.node : {}
    metaTags = pageInfo?.metatag_normalized.length > 0 ? pageInfo?.metatag_normalized : []
    const schema = pageInfo?.relationships?.field_seo_schema ? pageInfo?.relationships?.field_seo_schema : []
    const canonicalUrl = pageInfo?.metatags_on_page || {}

    components = typeof (pageInfo?.relationships?.field_component_type) !== "undefined" && Array.isArray(pageInfo?.relationships?.field_component_type) && pageInfo?.relationships?.field_component_type.length > 0 && pageInfo?.relationships?.field_component_type

    //banner section 
    const bannerSection = components.find(el => el?.name === "patient_care_banner_section");
    const bannerData = Object.keys(bannerSection).length > 0 && bannerSection?.relationships?.components[0];

    const bannerTitle = bannerData?.title;
    const bannerSubtitle = bannerData?.subtitle
    const bannerImageAltText = bannerData?.bannerImage?.alt || "Hinduja Hospital"
    const bannerImage = bannerData?.relationships?.bannerImage?.uri?.url;
    const bannerImageMobile = bannerData?.relationships?.mobileBannerImage?.uri?.url || bannerData?.relationships?.bannerImage?.uri?.url;

    //  outpatient and inpatient section
    const ioSection = components.find(el => el?.name === "Outpatient & Inpatient Services");
    const sectionName = typeof (ioSection) !== "undefined" && ioSection?.name !== "undefined" && ioSection?.name;

    // patient service section 
    const patSection = components.find(el => el?.field_name === "patient_service_section");
    const patSecTitle = typeof (patSection) !== "undefined" && patSection?.field_title !== "undefined" && patSection?.field_title
    const serviceCards = typeof (patSection) !== "undefined" && Array.isArray(patSection?.relationships?.field_components) && patSection?.relationships?.field_components.length > 0 && patSection?.relationships?.field_components

    // health checkup section 
    const healthSection = components.find(el => el?.field_name === "patient_care_health_checkup_section");
    const healthTitle = typeof (healthSection) !== "undefined" && healthSection?.field_title
    const healthCards = typeof (healthSection) !== "undefined" && Array.isArray(healthSection?.relationships?.field_components) && healthSection?.relationships?.field_components.length > 0 && healthSection?.relationships?.field_components
    const healthButton = typeof (healthSection) !== "undefined" && healthSection?.field_link !== "undefined" && healthSection?.field_link
    const btnText = typeof (healthButton) !== "undefined" && healthButton?.title !== "undefined" && healthButton?.title
    let btnLink = typeof (healthButton) !== "undefined" && healthButton?.uri !== "undefined" && healthButton?.uri

    if (btnLink) {
        {
            if (btnLink.includes("internal:")) {
                btnLink = btnLink.replace("internal:", "");
            }
        }

    }

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: [
                            "/assets/css/listing.css",
                            "/assets/css/about-through-the-ages.css",
                            "/assets/css/services.css",
                            "/assets/css/accessibility.css",
                        ],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage pt-first-section">
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                            {
                                bannerImage ?
                                    <>
                                        <img src={bannerImageMobile} alt={bannerImageAltText} className="d-md-none"/>
                                        <img src={bannerImage} alt={bannerImageAltText} className="d-md-block d-none"/>
                                    </>
                                    :
                                    <img
                                        src={defaultImageSrc}
                                        alt={bannerImageAltText} />
                            }
                        </picture>
                        <div className="banner_content text_left text_dark">
                            <div className="container">
                                <h1>{bannerTitle || ""}</h1>
                                <p>{bannerSubtitle || ""}</p>
                            </div>
                        </div>
                    </section>
                    {
                        (ioSection !== null && typeof(ioSection) !== "undefined" && ioSection !== "" && Object.keys(ioSection).length > 0) &&
                        <section className="services_section common_services section-bg section-py">
                            <div className="container">
                                <h2>
                                    {sectionName || ""}
                                </h2>
                                {
                                    ioSection?.relationships?.components.length > 0 &&
                                    <div className="row">
                                        {
                                            Array.isArray(ioSection.relationships.components) && ioSection.relationships.components.map((el, index) => {
                                                return (
                                                    <div key={index} className="col-md-6">
                                                        <div className="service_card">
                                                            <h3>{el?.title || ""}</h3>
                                                            <div dangerouslySetInnerHTML={{ __html: el?.description?.processed || "" }}>
                                                            </div>
                                                            <div className="btn_wrap">
                                                                <BtnLink
                                                                    data={el}
                                                                    classes="btn btn-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </section>
                    }
                    {/* {
                        components[2] &&
                        <section className="services_section common_services section-py text-center">
                            <div className="container">
                                <h2>
                                    {components[2]?.field_title || ""}
                                </h2>
                                <div dangerouslySetInnerHTML={{ __html: components[2]?.text?.processed }}>
                                </div>
                                <div className="btn_wrap">
                                    <BtnLink
                                        data={components[2]}
                                        classes="btn btn-primary"
                                    />
                                </div>
                            </div>
                        </section>
                    } */}
                    {
                        components[3] &&
                        <section className="services_section common_services three_cols section-py section-bg">
                            <div className="container">
                                <h2>{patSecTitle || ""}</h2>
                                {
                                    serviceCards &&
                                    <div className="row">
                                        {
                                            serviceCards.length > 0 && serviceCards.map((el, index) => {
                                                const image = el?.relationships?.image?.uri?.url;
                                                const altText = el?.image?.alt
                                                return (
                                                    <div className="col-md-4" key={index}>
                                                        <div className="service_card">
                                                            {
                                                                image ?
                                                                    <img src={image} alt={altText} className="service_card_icons_image" />
                                                                    :
                                                                    <figure>
                                                                        <img
                                                                            src={defaultPatientServiceSrc}
                                                                            alt={altText}
                                                                        />
                                                                    </figure>
                                                            }
                                                            <h3>{el?.title || ""}</h3>
                                                            <div dangerouslySetInnerHTML={{ __html: el?.description?.processed }}>
                                                            </div>
                                                            <div className="btn_wrap">
                                                                <BtnLink
                                                                    data={el}
                                                                    classes="btn btn-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </section>
                    }
                    {/* {
                        components[4] &&
                        <section className="services_section common_services section-py text-center">
                            <div className="container">
                                <h2>{components[4]?.field_title || ""}</h2>
                                <div dangerouslySetInnerHTML={{ __html: components[4]?.text?.processed }}>
                                </div>
                                <div className="btn_wrap">
                                    <BtnLink
                                        data={components[4]}
                                        classes="btn btn-primary"
                                    />
                                </div>
                            </div>
                        </section>
                    } */}
                    {
                        healthSection &&
                        <section className="section_healthcheckup section-py section-bg">
                            <div className="container">
                                <div className="heading-section text-center">
                                    <img
                                        src="/assets/images/icons/icon_health_checkup.svg"
                                        alt=""
                                    />
                                </div>
                                {
                                    healthCards &&
                                    <div className="checkup_slider">
                                        {
                                            healthCards.length > 0 && healthCards.map((el, index) => {
                                                const field_link = el?.link?.uri?.replace("internal:", "");
                                                return (
                                                    <div className="slide" key={index}>
                                                        <div className="checkup_card checkup_card_inner">
                                                            <h3 className="name">{el?.title || ""}</h3>
                                                            <div dangerouslySetInnerHTML={{ __html: el?.description?.processed || "" }}></div>
                                                            <Link to={el?.link?.uri ? field_link : "/"} className="btn btn-primary">{el?.link?.title}</Link>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                {
                                    btnLink && (
                                        <div className="btn_wrap text-center mt-4">
                                            <a href={btnLink} target="_blank" className="btn btn-primary">{btnText}</a>
                                        </div>
                                    )
                                }

                            </div>
                        </section>
                    }
                </main>
            </Layout>
        </>
    )
}
export default PatientCare
export const query = graphql`
query patientCare {

    allNodeTemplatePages(filter: {path: {alias: {regex: "/patient-care$/"}}}) {
  
      edges {
  
        node {
  
          id
  
          title
  
          path {
  
            alias
  
          }
          relationships{
            field_seo_schema{
                field_seo_title
                field_seo_schema_text_area
            }
          }
          metatag_normalized {
  
            attributes {
  
              content
  
              name
              property
  
            }
  
          }
          metatags_on_page: field_meta_tags{
            canonical_url
          }
          relationships {
  
            field_component_type {
  
              __typename
  
              ...ParagraphSectionpatientcare
  
              ... on paragraph__title_and_description_components {
  
                id
  
                field_name
  
                field_title
  
                field_link {
  
                  uri
  
                  title
  
                }
  
                relationships {
  
                  field_components {
  
                    ...ParagraphImageTitleDescLinkpatientcare
  
                  }
  
                }
  
              }
  
            }
  
          }
  
        }
  
      }
  
    }
  
  }
  
   
  
  fragment ParagraphSectionpatientcare on paragraph__section {
  
    id
  
    name: field_name
  
    relationships {
  
      components: field_components {
  
        __typename
  
        ...ParagraphBannerpatientcare
  
        ...ParagraphImageTitleDescLinkpatientcare
  
      }
  
    }
  
  }
  
   
  
  fragment ParagraphImageTitleDescLinkpatientcare on paragraph__image_title_desc_link {
  
    id
  
    title: field_title
  
    field_title_1
  
    description: field_description {
  
      processed
  
    }
  
    link: field_link {
  
      uri
  
      title
  
    }
  
    relationships {
  
      image: field_image {
  
        id
  
        uri {
  
          value
  
          url
  
        }
  
      }
  
    }
  
  }
  
   
  
  fragment ParagraphBannerpatientcare on paragraph__banner {
  
    id
  
    title: field_title
  
    subtitle: field_subtitle
  
    bannerImage: field_banner_image {
  
      alt
  
    }
  
    mobileBannerImage: field_mobile_banner {
  
      alt
  
    }
  
    bannerLink: field_banner_link {
  
      uri
  
      title
  
    }
  
    relationships {
  
      bannerImage: field_banner_image {
  
        id
  
        uri {
  
          value
  
          url
  
        }
  
      }
  
      mobileBannerImage: field_mobile_banner {
  
        id
  
        uri {
  
          url
  
          value
  
        }
  
      }
  
    }
  
  }
`